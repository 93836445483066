import React, { useState, useEffect } from 'react'
import ModalLayout from '../../../../widgets/Layout/Modal'
import { cond, equals } from 'ramda'
import SignInForm from '../SignInForm'
import RequestResetPasswordForm from '../RequestResetPasswordForm'
import SignUpForm from '../SignUpForm'
import styles from './Modal.module.css'
import PropTypes from 'prop-types'
import translate from '../../../../utilities/translate'
import useHistoryChange from '../../../../hooks/useHistoryChange'
import translations from './translations'
import { getLocales } from '../../../../utilities/locales'

const trans = translate(translations)

// Modal :: Props -> React.Component
const Modal = ({ locale, isOpen, onClose }) => {
  const [openedPanel, setOpenedPanel] = useState(null)

  const onSignIn = () => setOpenedPanel('signIn')
  const onSignUp = () => setOpenedPanel('signUp')
  const onResetPassword = () => setOpenedPanel('resetPassword')

  useEffect(onSignIn, [isOpen])

  useHistoryChange(onClose)

  return (
    <ModalLayout>
      <ModalLayout.Body locale={ locale } isOpen={ isOpen } onClose={ onClose } onCloseLabel={ trans(locale)('close') }>
        { isOpen
          ? <div className={ styles.wrapper }>
            { cond([
              [equals('signIn'), () => <SignInForm onSignUp={ onSignUp } onResetPassword={ onResetPassword } onClose={ onClose } />],
              [equals('signUp'), () => <SignUpForm onCancel={ onSignIn } onClose={ onClose } />],
              [equals('resetPassword'), () => <RequestResetPasswordForm onCancel={ onSignIn } onClose={ onClose } />],
            ])(openedPanel) }
          </div>
          : null
        }
      </ModalLayout.Body>
    </ModalLayout>
  )
}

Modal.propTypes = {
  locale: PropTypes.oneOf(getLocales()).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Modal
