import { includes } from 'ramda'
import View from './BottomNavbar'
import { connect } from 'react-redux'

// mapStateToProps :: State -> Props
const mapStateToProps = state => ({
  isHomePage: state.router.activeRoute.name === 'homepage',
  isNewsfeedPage: includes(state.router.activeRoute.name, [
    'newsFeedFr', 'newsFeedAr', 'newsFeedEn', 'newsFeedHe',
  ]),
  isLiveRadioPage: includes(state.router.activeRoute.name, [
    'LiveRadioAr', 'LiveRadioFr', 'LiveRadioEn', 'LiveRadioHe',
  ]),
  isShowsPage: includes(state.router.activeRoute.name, [
    'showsAr', 'showsFr', 'showsEn', 'showsHe',
  ]),
  locale: state.router.locale,
})

// BottomNavbar :: Props -> React.Component
const BottomNavbar = connect(mapStateToProps)(View)

export default BottomNavbar
