export default {
  english: {
    label: 'Quick access',
    content: 'Content',
    menu: 'Menu',
    footer: 'Footer',
    signInUrl: '/en/sign-in',
    signInLabel: 'Sign in',
    signOutLabel: 'Sign out',
  },
  french: {
    label: 'Accès rapide',
    content: 'Contenu',
    menu: 'Menu',
    signInUrl: '/fr/connexion',
    signInLabel: 'Connexion',
    signOutLabel: 'Deconnexion',
  },
  arabic: {
    label: 'شريط أدوات الوصول السريع',
    content: 'محتوى',
    menu: 'قائمة طعام',
    footer: 'تذييل',
    signInUrl: '/ar/تسجيل-الدخول',
    signInLabel: 'تسجيل الدخول',
    signOutLabel: 'تسجيل الخروج',
  },
  hebrew: {
    label: 'גישה מהירה',
    content: 'תוכן',
    menu: 'תפריט',
    footer: '',
    signInUrl: '/he/sign-in',
    signInLabel: 'התחבר',
    signOutLabel: 'התנתק',
  },
}
