import { combineEpics, ofType } from 'redux-observable'
import { logObservableError } from '../utilities/logs'
import { cond, T } from 'ramda'
import { map, take } from 'rxjs'
import { load } from '../store/modules/viewport'
import { ROUTE_FOUND } from '../store/modules/router'
import { HYDRATION_COMPLETED } from '../store/modules/app'
import { DESKTOP_VIEWPORT, MOBILE_VIEWPORT } from '../utilities/constants'

// loadEpic :: Epic -> Observable Action.LOAD
export const loadEpic = (action$, _, { logger, window }) =>
  action$.pipe(
    ofType(ROUTE_FOUND, HYDRATION_COMPLETED),
    take(1),
    map(() => load(
      cond([
        [window => window.outerWidth <= 600, () => MOBILE_VIEWPORT],
        [T, () => DESKTOP_VIEWPORT],
      ])(window),
    )),
    logObservableError('viewport :: loadEpic', logger),
  )

export default combineEpics(loadEpic)
