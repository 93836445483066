import styles from './SkipLinksNavigation.module.css'
import React from 'react'
import translate from '../../../../utilities/translate'
import translations from './translations'
import PropTypes from 'prop-types'
import { getLocales } from '../../../../utilities/locales'
import Link from '../../../../widgets/Link/Link'

// trans :: String -> String -> String
const trans = translate(translations)

// SkipLinksNavigation :: Props -> React.Component
const SkipLinksNavigation = React.memo(({ locale, user, onSignOut }) =>
  <nav className={ styles.wrapper } aria-label={ trans(locale)('label') }>
    <ul className={ styles.list }>
      <li>
        <Link className={ styles.link } href="#navigation-content">Content</Link>
      </li>
      <li>
        <Link className={ styles.link } href="#navigation-menu">Menu</Link>
      </li>
      <li>
        <Link className={ styles.link } href="#navigation-footer">Footer</Link>
      </li>
      { null !== user
        ? <li>
          <Link onClick={ onSignOut } className={ styles.link } href="#sign-out">{ trans(locale)('signOutLabel') }</Link>
        </li>
        : <li>
          <Link className={ styles.link } href={ trans(locale)('signInUrl') }>{ trans(locale)('signInLabel') }</Link>
        </li>
      }

    </ul>
  </nav>,
)

SkipLinksNavigation.propTypes = {
  locale: PropTypes.oneOf(getLocales()).isRequired,
  user: PropTypes.object,
  onSignOut: PropTypes.func.isRequired,
}

export default SkipLinksNavigation
