import { createReducer } from '../../utilities/miscellaneous'

// viewport initial state
export const INITIAL_STATE = {
  viewport: null,
}

const LOAD = 'viewport/LOAD'

// load :: String -> Action.LOAD
export const load = viewport => ({
  type: LOAD,
  viewport,
})

export default createReducer(INITIAL_STATE, {
  [LOAD]: (state, { viewport }) => ({
    ...state,
    viewport,
  }),
})
