import View from './SkipLinksNavigation'
import { connect } from 'react-redux'
import { compose } from 'ramda'
import { signOut } from '../../../../store/modules/session'

// mapStateToProps :: State -> Props
const mapStateToProps = state => ({
  locale: state.router.locale,
  user: state.session.user,
})

// mapDispatchToProps :: (Action * -> State) -> Props
const mapDispatchToProps = dispatch => ({
  onSignOut: compose(dispatch, signOut),
})

// SkipLinksNavigation :: Props -> React.Component
const SkipLinksNavigation = connect(mapStateToProps, mapDispatchToProps)(View)

export default SkipLinksNavigation
