export default {
  english: {
    title: 'Sign Up',
    nickname: 'Nickname',
    email: 'E-mail',
    password: 'Password',
    repeatPassword: 'Repeat your password',
    terms: 'I accept the terms of use.',
    termsUrl: '/en/terms-of-service',
    submit: 'Create my profile',
    error: 'An error has occured, check your information and try again',
    newsletter: 'I subscribe to i24NEWS\'s newsletter',
    cancel: 'Cancel',
    welcome: 'Welcome {{ nickname }}!',
    welcomeText: 'You are now a member of i24NEWS. Thank you for registering!',
    back: 'Back to my navigation',
    passwordMismatch: 'Passwords do not match',
    passwordFormat: '8 characters minimum with at least one letter and one number',
    mandatoryFields: 'Fields marked with * are mandatory',
    signInUrl: '/en/sign-in',
    errors: {
      invalidEmail: 'Email field should be a valid email address',
      invalidPassword: 'Your password must have at least 8 characters and contain at least one letter and one number.',
      passwordMismatch: 'Your passwords must be the same.',
      termsRequired: 'You must accept the terms of use',
      captchaRequired: 'You must complete the captcha field',
    },
  },
  french: {
    title: 'Inscription',
    nickname: 'Pseudo',
    email: 'E-mail',
    password: 'Mot de passe',
    repeatPassword: 'Répétez votre mot de passe',
    terms: 'J\'accepte les conditions d\'utilisation.',
    termsUrl: '/fr/conditions-generales-d-utilisation',
    submit: 'Créer mon compte',
    error: 'Une erreur est survenue, vérifier vos informations et réessayez',
    newsletter: 'Je m\'abonne à la newsletter d\'i24NEWS',
    cancel: 'Annuler',
    welcome: 'Bienvenue {{ nickname }} !',
    welcomeText: 'Vous êtes désormais membre d\'i24NEWS. Merci pour votre inscription !',
    back: 'Retour à la navigation',
    passwordMismatch: 'Les mots de passe ne correspondent pas',
    passwordFormat: '8 caractères minimum avec au moins une lettre et un chiffre',
    mandatoryFields: 'Les champs marqués d\'un * sont obligatoires',
    signInUrl: '/fr/connexion',
    errors: {
      invalidEmail: 'Le champ e-mail doit être une adresse e-mail valide',
      invalidPassword: 'Votre mot de passe doit contenir au moins 8 caractères et contenir au moins une lettre et un chiffre.',
      passwordMismatch: 'Les champs mot de passe doivent correspondre.',
      termsRequired: 'Vous devez accepter les conditions d\'utilisation',
      captchaRequired: 'Vous devez renseigner le champ captcha',
    },
  },
  arabic: {
    title: 'الخروج',
    nickname: 'الاسم المستعار',
    email: 'البريد الإلكتروني',
    password: 'كلمة مرور',
    repeatPassword: 'كرر كلمة المرور',
    terms: 'أقبل شروط الاستخدام',
    termsUrl: '/ar/شروط-الخدمة',
    submit: 'تسجيل',
    error: 'لقد حدث خطأ ، تحقق من معلوماتك وحاول مرة أخرى',
    newsletter: 'اشتركت في النشرة الإخبارية i24NEWS',
    cancel: 'إلغاء',
    welcome: 'اهلا بك {{ nickname }}!',
    welcomeText: 'اصبحت الآن عضوا في i24NEWS. شكرا لتسجيلك!',
    back: 'العودة الى الخيارات السابقة',
    passwordMismatch: 'كلمة المرور غير مطابقة',
    passwordFormat: 'يجب ان تكون كلمة المرور من 8 حروف يشمل ارقام على الاقل',
    mandatoryFields: 'الحقول التي تحمل علامة * إلزامية',
    signInUrl: '/ar/تسجيل-الدخول',
    errors: {
      invalidEmail: 'يجب أن يكون حقل البريد الإلكتروني عنوان بريد إلكتروني صالح',
      invalidPassword: 'يجب أن تتكون كلمة المرور الخاصة بك من 8 أحرف على الأقل وأن تحتوي على حرف واحد ورقم واحد على الأقل',
      passwordMismatch: 'كلمة المرور يجب ان تكون ذاتها',
      termsRequired: 'يجب عليك قبول شروط الاستخدام',
      captchaRequired: 'يجب عليك إكمال حقل الكابتشا',
    },
  },
  hebrew: {
    title: 'הירשם',
    nickname: 'כינוי',
    email: 'דואר אלקטרוני',
    password: 'סיסמה',
    repeatPassword: 'חזור על הסיסמה',
    terms: 'אני מקבל את תנאי השימוש',
    termsUrl: '/he/terms-of-service',
    submit: 'צור פרופיל',
    error: 'אירעה שגיאה, בדוק את הפרטים ונסה שנית',
    newsletter: 'אני נרשם לניוזלטר של i24NEWS',
    cancel: 'ביטול/ בטל',
    welcome: 'ברוך הבא {{ nickname }}!',
    welcomeText: 'כעת אתה חבר ב-i24NEWS. תודה שנרשמת!',
    back: 'חזרה לניווט שלי',
    passwordFormat: 'לפחות שמונה תווים, הכוללים לפחות אות אחת ומספר אחד',
    mandatoryFields: 'שדות המסומנים ב-* הם חובה',
    signInUrl: '/he/sign-in',
    errors: {
      invalidEmail: 'כתובת האימייל לא תקינה',
      invalidPassword: 'הסיסמה חייבת לכלול לפחות שמונה תווים, כולל תו אחד ומספר אחד',
      passwordMismatch: 'הסיסמאות אינן זהות',
      termsRequired: 'עליך לאשר את תנאי השימוש',
      captchaRequired: "עליך להשלים את שדה הקאפצ'ה",
    },
  },
}
